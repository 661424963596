import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import moment from 'moment';
import { connect } from 'react-redux';

import { PAGES, CURRENCY } from 'config';
import { daysFromNow } from 'utilities';
import { DELETE_BOOKING, GET_BOOKINGS } from 'actions/booking';
import SVGCross from '../../../public/svg/cross.svg';

const FORMAT_CREATED_TITLE = (days) => {
  if (parseInt(days, 10) === 1) {
    return `${days} day ago`;
  }
  if (parseInt(days, 10) === 0) {
    return 'Less than a day ago';
  }
  return `${days} days ago`;
};

const BookingCard = ({
  booking,
  tour,
  user,
  dispatch,
}) => (
  <div className={`u-card c-booking-card c-booking-card--${booking.complete || booking.total_paid > 0 || booking.total === booking.total_paid ? 'complete' : 'incomplete'}`} key={booking.id}>
    <span className="c-booking-card__status c-heading c-heading--h5">
      {(() => {
        if (booking.complete || booking.total_paid || booking.total === booking.total_paid) {
          return 'Booked';
        } else if (booking.depart_less_than_30_days) {
          return 'On Request';
        }
        else {
          return 'Incomplete';
        }
      })()}
    </span>
    {!booking.complete && booking.total_paid === 0
      ? (
        <button
          type="button"
          title="Delete this booking"
          className="c-booking-card__delete-btn"
          onClick={(e) => {
            e.preventDefault();
            (async () => {
              const RESULT = window.confirm('Are you sure you want to delete this booking? It cannot be undone'); // eslint-disable-line
              if (RESULT) {
                await DELETE_BOOKING(booking.id, user.session.token, user.session.email);
                await GET_BOOKINGS(dispatch, user.session.token, user.session.email);
              }
            })();
          }}
        >
          <span className="u-sr-only">Delete</span>
          <SVGCross />
        </button>
      ) : null }
    <div className="c-booking-card__title">
      <h3 className="c-heading c-heading--h3">
        {booking.tour_name}
      </h3>
      {tour && (
        <h5 className="c-heading c-heading--h5">
          {tour.number_of_days} days
        </h5>
      )}
    </div>
    <ol className="u-reset-list c-booking-card__details">
      <li>
        <h6>Created</h6> <span className="c-heading c-heading--h5">{FORMAT_CREATED_TITLE(daysFromNow(booking.created_at))}</span>
      </li>
      <li>
        <h6>Departure</h6> <span className="c-heading c-heading--h5">{moment(booking.departure_date).format('D MMM YYYY')}</span>
      </li>
      <li>
        <h6>Total</h6> <span className="c-heading c-heading--h5">${booking.total}</span>
      </li>
      <li>
        <h6>Paid</h6> <span className="c-heading c-heading--h5">{CURRENCY} ${booking.total_paid}</span>
      </li>
      {(!booking.complete && booking.total_paid == 0) && booking.depart_less_than_30_days && (
        <li>
          <span className="c-booking-expired-info">
            This booking has now expired and is available on request only due to the departure date. If you’d like to make this booking, please click the button below.
          </span>
        </li>
      )}
    </ol>
    <div className="c-booking-card__actions">
      {(!booking.complete && booking.total_paid == 0) && booking.depart_less_than_30_days ? (
        <Link
          to={PAGES.LATE_REQUEST.PATH}
          className="c-button c-button--border c-button--block"
        >
          On Request
        </Link>
      ) : (
        <>
          {booking.balance_remaining > 0 && booking.agree_with_the_conditions && (
            <Link
              to={`${PAGES.BOOKINGS_PAYMENT.PATH}?bookingId=${booking.id}&tour=${booking.tour_slug}`}
              className="c-button c-button--wide c-button--primary"
            >
              Make a payment
            </Link>
          )}
          <Link
            to={`${booking.locked ? PAGES.BOOKINGS_REVIEW.PATH : PAGES.BOOKINGS_DETAILS.PATH}?bookingId=${booking.id}&tour=${booking.tour_slug}`}
            className="c-button c-button--wide c-button--border"
          >
            Manage Tour
          </Link>
        </>
      )}
    </div>
  </div>
);

BookingCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  tour: PropTypes.object,
};

BookingCard.defaultProps = {
  tour: null,
};

const mapStateToProps = state => ({
  dispatch: state.dispatch,
  user: state.user,
});

export default connect(mapStateToProps)(BookingCard);
