import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import queryString from 'query-string';

import {
  META_DATA,
  META_TITLE,
  PAGES,
  BASE_URL,
} from 'config';
import { mapStateToProps } from 'utilities';
import { Header, BasicFooter, BookingNav } from 'components';
import { GET_BOOKINGS, GET_BOOKING } from 'actions/booking';
import 'styles/index.scss';

class BookingLayout extends PureComponent {
  constructor(props) {
    super(props);
    // the booking index page requires none of below
    if (props.page === 'bookings') {
      props.dispatch({
        type: 'BOOKINGS_RESET',
      });
      return;
    }

    if (typeof window === 'undefined') return;

    // get booking ID from URL
    const PARSED = queryString.parse(window.location.search);

    if ((PARSED.bookingId && PARSED.tour) && !props.user.authenticated) {
      navigate(`${PAGES.EXTRA_DETAILS.PATH}?bookingId=${PARSED.bookingId}&tour=${PARSED.tour}`);
      return;
    }

    // If we have no booking ID - redirect to the start of booking process
    if (!PARSED.bookingId || !props.user.authenticated) {
      navigate(PAGES.BOOKINGS.PATH);
      return;
    }
    (async () => {
      // Get all bookings for the current user
      const USER_BOOKINGS = await GET_BOOKINGS(props.dispatch, props.user.session.token, props.user.session.email);

      // Find selected booking
      const BOOKING = props.user.session.is_admin
        ? (await GET_BOOKING(props.dispatch, PARSED.bookingId, props.user.session.token, props.user.session.email)).data.currentBooking
        : USER_BOOKINGS.data.bookings.find((booking => booking && booking.id === PARSED.bookingId));

      // If no booking was found, redirect
      if (!BOOKING) {
        navigate(PAGES.BOOKINGS.PATH);
        return;
      }

      // If user hasn't accepted terms, send them back to traveller details page
      if (props.page !== 'bookings/traveller-details' && !BOOKING.agree_with_the_conditions) {
        navigate(`${PAGES.BOOKINGS_DETAILS.PATH}?bookingId=${PARSED.bookingId}&tour=${PARSED.tour}`);
        return;
      }

      // redirect users if booking is locked
      if (['bookings/traveller-details', 'bookings/essentials', 'bookings/add-ons'].includes(props.page) && BOOKING.locked) {
        navigate(`${PAGES.BOOKINGS_REVIEW.PATH}?bookingId=${PARSED.bookingId}&tour=${PARSED.tour}`);
        return;
      }

      // Get ALL data for this booking
      const BOOKING_DETAIL = await GET_BOOKING(props.dispatch, BOOKING.id, props.user.session.token, props.user.session.email);

      if (!BOOKING_DETAIL || !BOOKING_DETAIL.success) {
        props.dispatch({
          type: 'MESSAGES_ADD',
          payload: {
            id: 'failed-to-find-booking',
            type: 'error',
            content: 'Failed to get booking details.',
          },
        });
        navigate(PAGES.BOOKINGS.PATH);
      }
    })();
  }

  render() {
    const { page, children } = this.props;
    return (
      <>
        <Helmet
          title={META_TITLE}
          meta={META_DATA}
          bodyAttributes={{
            class: `h-page--${page} h-section-booking u-body--top`,
          }}
          link={[{ rel: 'canonical', href: `${BASE_URL}/bookings/` }]}
        >
          <html lang="en" />
        </Helmet>
        <Header forceActive useBookingNav />
        <main className="l-main l-main--alt l-main--booking" role="main">
          <section className="l-section l-bookings">
            <div className="l-container">
              <span className="u-hide-screen-extra-large-up"><BookingNav /></span>
              {children}
            </div>
          </section>
          <BasicFooter />
        </main>
      </>
    );
  }
}

BookingLayout.propTypes = {
  page: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps)(BookingLayout);
